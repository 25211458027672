<template>
  <div class="map">
    <l-map
      :center="center"
      :zoom="zoom"
      class="map"
      ref="map"
      @update:zoom="zoomUpdated"
      @update:center="centerUpdated"
    >
      <l-tile-layer :url="url"></l-tile-layer>
      <l-marker :lat-lng="withPopup">
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import {LMap, LTileLayer, LMarker} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


export default {
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //43.6154118,7.0718974
      center: [43.6154118, 7.0718974],
      zoom: 14,
      location:null,
      gettingLocation: false,
      errorStr:null,
      withPopup: latLng(43.6154118, 7.0718974)
    }
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom
      console.log(this.markers)
    },
    centerUpdated(center) {
      this.center = center
    },
    created() {
      //do we support geolocation
      if (!("geolocation" in navigator)) {
        this.errorStr = 'Geolocation is not available.';
        return;
      }

      this.gettingLocation = true;
      // get position
      navigator.geolocation.getCurrentPosition(pos => {
        this.gettingLocation = false;
        this.location = pos;
      }, err => {
        this.gettingLocation = false;
        this.errorStr = err.message;
      })
    }
  }
}
</script>

<style>
.map {
  width: 100%;
  height: 210px;
  overflow: hidden;
}
</style>
