<template>
  <div>
    <CModal
        title="Alerte chaudière"
        color="warning"
        :show.sync="warningModal"
    >
      messageWarning
    </CModal>
    <CRow>
      <CCol sm="12" lg="6">
        <CRow>
          <CCol sm="6" lg="6">
            <CWidgetIcon
                :header="headerAlerte"
                :text="textAlerte"
                :color="couleurAlerte"
                :icon-padding="false"
            >
              <CDropdown
                  color="transparent p-0"
              >
                <template #toggler-content>
                  <CIcon name="cil-settings"/>
                </template>
                <CDropdownItem v-bind:href="'/#/detail/?type=alerte'">Historique sur la journée</CDropdownItem>
                <CDropdownItem v-bind:href="'/#/detail/?type=alerte'">Historique sur la semaine</CDropdownItem>
                <CDropdownItem v-bind:href="'/#/detail/?type=alerte'">Historique sur le mois</CDropdownItem>
                <CDropdownItem v-bind:href="'/#/detail/?type=alerte'">Historique sur l'année</CDropdownItem>
              </CDropdown>
            </CWidgetIcon>
          </CCol>
          <CCol sm="6" lg="6">
            <CWidgetIcon
                header="La chaudière est sous surveillance"
                :text="'Dernière actualisation : ' + temps"
                color="gradient-success"
                :icon-padding="false"
            >
              <CDropdown
                  color="transparent p-0"
              >
                <template #toggler-content>
                  <CIcon name="cil-settings"/>
                </template>
                <CDropdownItem>Historique sur la journée</CDropdownItem>
                <CDropdownItem>Historique sur la semaine</CDropdownItem>
                <CDropdownItem>Historique sur le mois</CDropdownItem>
                <CDropdownItem>Historique sur l'année</CDropdownItem>
              </CDropdown>
            </CWidgetIcon>
          </CCol>
          <CCol sm="6" lg="6">
            <CWidgetIcon
                header="Documentation"
                text="Documentation disponible"
                color="gradient-info"
                :icon-padding="false"
            >
              <CIcon name="cil-puzzle" width="24"/>
              <template #footer>
                <CCardFooter class="px-3 py-2">
                  <CLink
                      class="font-weight-bold font-xs text-muted d-flex justify-content-between"
                      href="https://www.saunierduval.fr/france/download/les-anciennes-documentations/saunier-duval-isofast-21-condens-notice-dinstallation-2013-07-309903.pdf"
                      target="_blank"
                  >
                    Consulter la documentation
                    <CIcon name="cil-arrow-right" width="16"/>
                  </CLink>
                </CCardFooter>
              </template>
            </CWidgetIcon>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12" lg="6">
        <Carte/>
      </CCol>
    </CRow>
    <WidgetsDropdown/>
  </div>
</template>

<script>

import WidgetsDropdown from './widgets/WidgetsDropdown'
import Carte from './widgets/Carte'
import http from "@/http-common";


export default {
  name: 'Dashboard',

  components: {
    WidgetsDropdown,
    Carte
  },

  data () {
    return {
      warningModal: false,
      messageWarning: "",
      temps: "calcul...",
      textAlerte: "calcul...",
      couleurAlerte: "gradient-info",
      headerAlerte: "Analyse...",
      timer: ''
    }
  },

  created () {
    this.timer = setInterval(this.maj, 3000);
  },

  mounted() {
    this.$nextTick(() => {
      this.maj()
    })
  },

  methods: {
    maj() {
      http.get('/device_data/search/identifiant_device?id=ltit-device-heltec-lora32-v2-1-589a&size=12&sort=temps,desc')
          .then(response => {
            this.temps = response.data._embedded.device_data.map(x => x.temps)[0].replace('T', ' ')

            this.boilerAlarmState = response.data._embedded.device_data.map(x => JSON.parse(x.donnees).boilerAlarmState)[0]
            this.boilerCommandState = response.data._embedded.device_data.map(x => JSON.parse(x.donnees).boilerCommandState)[0]
            this.boilerPowerSupplyState = response.data._embedded.device_data.map(x => JSON.parse(x.donnees).boilerPowerSupplyState)[0]

            this.textAlerte = ''
            if (this.boilerAlarmState == '1') {
              this.textAlerte = 'Défaut brûleur, chaudière mise en sécurité'
            }
            if (this.boilerCommandState == '0') {
              this.textAlerte = this.textAlerte + ' Défaut chaudière : chaudière désactivée'
            }
            if (this.boilerPowerSupplyState == '0') {
              this.textAlerte = this.textAlerte + ' Chaudière non alimentée'
            }


            if (this.textAlerte != '') {
              this.couleurAlerte = "gradient-danger"
              this.headerAlerte = "La chaudière n'est pas fonctionnelle"
            } else {
              this.textAlerte = "Pas d'alerte, la chaudière est alimentée en courant"
              this.couleurAlerte = "gradient-success"
              this.headerAlerte = "La chaudière est fonctionnelle"
            }
          })
    }
  }
}
</script>

<style>
.text-uppercase {
  text-transform: initial !important;
}
</style>
